const colors = {
    bkg: "#2E2E2E",
    foothead: '#1E4160',
    box: '#1E4160',
    txt: "#FFFFFF",
    txt_2:"#ABEA87",
    txt_3: "#F3FF83"

    // Add more colors as needed
  };
  
  export default colors;